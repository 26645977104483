import React, { useState, useEffect } from "react";
import axios from "../../helpers/axios";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";

// import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";

export default function CompletedMatches() {
  const [activeTab, setActiveTab] = useState(1);
  const [activeTabSport, setActiveTabSport] = useState(0);
  const [value, onChange] = useState([new Date(), new Date()]);
  const [matches, setMatches] = useState([]);
  const [sports_id, setSports_id] = useState(4);
  const [profitAndLoss, setProfitAndLoss] = useState({});
  const navigate = useNavigate();

  const getCompletedMatchs = async (sports_id) => {
    setMatches([]);
    try {
      let res = await axios.get("/matches/getCompletedMatches/" + sports_id);
      if (res.data.status) {
        setMatches(res.data.dataobj);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getProfitAndLossOfCompletedMatchs = async () => {
    try {
      let res = await axios.get("/users/getProfitAndLossOfCompletedMatchs", {
        params: {
          user_id: localStorage.getItem("user_id"),
        },
      });
      if (res.data.status) {
        setProfitAndLoss(res.data.profit_loss_ledger);
      }
    } catch (err) {
      console.log(err);
      setProfitAndLoss({});
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getCompletedMatchs(sports_id);
    };
    getProfitAndLossOfCompletedMatchs();
    fetchData();
  }, [activeTab, sports_id]);

  const columns = [
    {
      field: "match_id",
      headerName: "ID",
      flex: 1,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "match_name",
      headerName: "Title",
      flex: 2,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Link
          to={`/live-matches/reports/${params.row.matchObj.id}`}
          style={{
            color: "#0080FF",
            display: "block",
            width: "100%",
          }}
        >
          {params.row.match_name}
        </Link>
      ),
      wrap: true,
    },
    {
      field: "start_time",
      headerName: "Date",
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: (params) => (
        <span style={{ width: "100%", textAlign: "center" }}>
          {formatDateAndTime(params.value)}
        </span>
      ),
    },
    {
      field: "declare",
      headerName: "Declare",
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) =>
        params.row.matchObj.status === "declared" ? "No" : "Yes",
    },
    {
      field: "winner",
      headerName: "Won By",
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "profit_loss",
      headerName: "Profit / Loss",
      flex: 1, // Adjust the width proportionally
      disableColumnMenu: true,
      renderCell: (params) =>
        params.value ? params.value : "Report Not Available",
    },
  ];

  function formatDateAndTime(dateString) {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  const returnCurrentTabTable = () => {
    if (activeTab == 1 && activeTabSport == 0)
      return (
        <div style={{ height: 800, width: "100%" }}>
          <div style={{ fontWeight: "Bold", paddingBottom: "10px" }}>
            Completed Cricket Matches
          </div>

          <DataGrid
            rows={matches}
            columns={columns}
            getRowId={(row) => row._id}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 20, 30]}
            autoHeight
            disableSelectionOnClick
          />
        </div>
      );
    else if (activeTab == 1 && activeTabSport == 1)
      return (
        <div style={{ height: 800, width: "100%" }}>
          <div style={{ fontWeight: "Bold", paddingBottom: "10px" }}>
            Completed Soccer Matches
          </div>

          <DataGrid
            rows={matches}
            columns={columns}
            getRowId={(row) => row._id}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 20, 30]}
            autoHeight
            disableSelectionOnClick
          />
        </div>
      );
    else if (activeTab == 1 && activeTabSport == 2)
      return (
        <div style={{ height: 800, width: "100%" }}>
          <div style={{ fontWeight: "Bold", paddingBottom: "10px" }}>
            Completed Tennis Matches
          </div>

          <DataGrid
            rows={matches}
            columns={columns}
            getRowId={(row) => row._id}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 20, 30]}
            autoHeight
            disableSelectionOnClick
          />
        </div>
      );
    else return <></>;
  };

  return (
    <div style={{ overflow: "auto" }}>
      <div
        style={{
          padding: "16px 0",
          display: "grid",
          placeItems: "center",
          background: "",
          borderRadius: "8px",
          marginBottom: "8px",
        }}
      >
        <div style={{ backgroundColor: "white" }}>
          {/* <DateRangePicker
            onChange={onChange}
            value={value}
            // clearIcon={<MdOutlineClear color="white" />}
            // calendarIcon={<SlCalender color="white" />}
            format={"dd-MM-y"}
          /> */}
        </div>

        <Tabs
          value={activeTabSport}
          sx={{
            ".Mui-selected": {
              color: `#fff`,
              outline: "none",
            },
            "&:hover": {
              outline: "none",
            },
          }}
          TabIndicatorProps={{ style: { background: "#896cef" } }}
          aria-label="tabs example"
          onChange={(e, newVal) => {
            setActiveTabSport(newVal);
          }}
        >
          <Tab
            value={0}
            label={
              <span
                style={{ color: activeTabSport == 0 ? "#896cef" : "#808080" }}
              >
                Cricket
              </span>
            }
          />
          <Tab
            value={1}
            label={
              <span
                style={{ color: activeTabSport == 1 ? "#896cef" : "#808080" }}
              >
                Soccer
              </span>
            }
          />
          <Tab
            value={2}
            label={
              <span
                style={{ color: activeTabSport == 2 ? "#896cef" : "#808080" }}
              >
                Tennis
              </span>
            }
          />
        </Tabs>
      </div>
      {returnCurrentTabTable()}
    </div>
  );
}
