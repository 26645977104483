import React, { useEffect, useState } from "react";
// import { usePDF } from "react-to-pdf";
import axios from "../../helpers/axios";
import { Tab, Tabs } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";

export default function CollectionReport() {
  const [activeTab, setActiveTab] = useState(1);
  //   const { toPDF, targetRef } = usePDF({ filename: "page.pdf" });
  const [activeTabSport, setActiveTabSport] = useState(0);
  const [lenaHai, setLenaHai] = useState([]);
  const [denaHai, setDenaHai] = useState([]);
  const [clearHai, setClearHai] = useState([]);
  const [allCollections, setAllCollections] = useState([]);

  const columns = [
    {
      field: "collect_from_username",
      headerName: "Client",
      width: 200,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "amount",
      headerName: "Balance",
      width: 200,
      disableColumnMenu: true,
      flex: 1,
    },
  ];

  const data = [
    {
      id: 1,
      pid: "idk",
    },
    {
      id: 2,
      pid: "idk",
    },
  ];

  useEffect(() => {
    const getCollection = async () => {
      try {
        const res = await axios.get("/users/getCollectionReport", {
          params: { user_id: localStorage.getItem("_id") },
        });
        if (res.data.status) {
          const data = res.data.dataobj;
          setAllCollections(data);
          setDenaHai(data.filter((i) => i.amount > 0));
          setLenaHai(data.filter((i) => i.amount < 0));
          setClearHai(data.filter((i) => i.amount == 0));
        }
      } catch (er) {
        console.log(er);
      }
    };
    getCollection();
  }, []);
  //   const actionsMemo = React.useMemo(
  //     () => (
  //       <div style={{ display: "flex", fontSize: "1rem" }}>
  //         <CSVGenerator columns={columns} data={data} />
  //         <DownloadPdf
  //           columns={columns}
  //           data={data}
  //           tableName={"Table Name"}
  //         />
  //       </div>
  //     ),
  //     []
  //   );

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const returnCurrentTabTable = () => {
    if (activeTab == 1 && activeTabSport == 0)
      return (
        <div style={{ height: 800, width: "100%" }}>
          <div style={{ fontWeight: "Bold", paddingBottom: "10px" }}>
            PAYMENT RECEIVING FROM (Lena hai)
          </div>

          <DataGrid
            rows={lenaHai}
            columns={columns}
            getRowId={(row) => row._id}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            slots={{
              toolbar: CustomToolbar,
            }}
            pageSizeOptions={[10, 20, 30]}
            autoHeight
            disableSelectionOnClick
          />
        </div>
      );
    else if (activeTab == 1 && activeTabSport == 1)
      return (
        <div style={{ height: 800, width: "100%" }}>
          <div style={{ fontWeight: "Bold", paddingBottom: "10px" }}>
            PAYMENT PAID TO (Dena hai)
          </div>

          <DataGrid
            rows={denaHai}
            columns={columns}
            getRowId={(row) => row._id}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 20, 30]}
            slots={{
              toolbar: CustomToolbar,
            }}
            autoHeight
            disableSelectionOnClick
          />
        </div>
      );
    else if (activeTab == 1 && activeTabSport == 2)
      return (
        <div style={{ height: 800, width: "100%" }}>
          <div style={{ fontWeight: "Bold", paddingBottom: "10px" }}>
            PAYMENT CLAER (Clear hai)
          </div>

          <DataGrid
            rows={clearHai}
            columns={columns}
            getRowId={(row) => row._id}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            slots={{
              toolbar: CustomToolbar,
            }}
            pageSizeOptions={[10, 20, 30]}
            autoHeight
            disableSelectionOnClick
          />
        </div>
      );
    else return <></>;
  };

  return (
    <div>
      <div
        style={{
          padding: "16px 0",
          display: "grid",
          placeItems: "center",
          background: "",
          borderRadius: "8px",
          marginBottom: "8px",
        }}
      >
        <Tabs
          value={activeTabSport}
          sx={{
            ".Mui-selected": {
              color: `#fff`,
              outline: "none",
            },
            "&:hover": {
              outline: "none",
            },
          }}
          TabIndicatorProps={{ style: { background: "#896cef" } }}
          aria-label="tabs example"
          onChange={(e, newVal) => {
            setActiveTabSport(newVal);
          }}
        >
          <Tab
            value={0}
            label={
              <span
                style={{ color: activeTabSport == 0 ? "#896cef" : "#808080" }}
              >
                Lena hai
              </span>
            }
          />
          <Tab
            value={1}
            label={
              <span
                style={{ color: activeTabSport == 1 ? "#896cef" : "#808080" }}
              >
                Dena hai
              </span>
            }
          />
          <Tab
            value={2}
            label={
              <span
                style={{ color: activeTabSport == 2 ? "#896cef" : "#808080" }}
              >
                Clear hai
              </span>
            }
          />
        </Tabs>
      </div>
      <div>
        {/* <button style={{color:"red"}} onClick={() => generatePDF(getTargetElement, options)}>Download PDF</button> */}
        <div id="content-id">{returnCurrentTabTable()}</div>
      </div>
    </div>
  );
}
