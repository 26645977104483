import {Navigate, Outlet, useNavigate, useRoutes} from 'react-router-dom';

import LoginPage from '../pages/LoginPage';
import PrivateRoutes from './PrivateRoute';
import DashboardLayout from '../layouts/dashboard/DashboardLayout';
import Dashboard from '../pages/Dashboard';
import {useEffect} from 'react';
import ManageUsers from "../pages/Manage/ManageUsers";
import ManageSuperAdmin from "../pages/Manage/ManageSuperAdmin";
import ManageAdmin from "../pages/Manage/ManageAdmin";
import ManageAgent from "../pages/Manage/ManageAgent";
import ManageStokist from "../pages/Manage/ManageStokist";
import ManageSuperStokist from "../pages/Manage/ManageSuperStokist";
import LiveMatches from "../pages/Matches/LiveMatches";
import CompletedMatches from "../pages/Matches/CompletedMatches";
import CasinoReports from "../pages/CasinoReport/CasinoReports";
import MyClients from "../pages/ManageClients/MyClient";
import BlockedClients from "../pages/ManageClients/BlockedClients";
import Languages from "../pages/Languages/Languages";
import CollectionReport from "../pages/ManageLedgers/CollectionReport";
import CompanyLedger from "../pages/ManageLedgers/CompanyLedger";
import CommissionLimits from "../pages/ManageClients/CommissionLimits";
import ProfitAndLoss from "../pages/Settings/ProfitAndLoss";
import MyStatements from "../pages/Settings/MyStatements";
import ManagePassword from "../pages/Settings/ManagePassword";
import BlockedSports from "../pages/Settings/BlockedSports";
import Profile from "../pages/Settings/Profile";
import Master from "../pages/Members/Master";
import SeriesListing from "../pages/Settings/SeriesListing";
import Reports from "../pages/Matches/Reports";
import SessionReport from "../pages/Matches/SessionReport";
import LiveReport from "../pages/Matches/LiveReport";

export default function Router() {
  const navigate = useNavigate();

  useEffect(() => {
    const isAuthenticated = localStorage.getItem("isAuthenticated");
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, []);

  return useRoutes([
    {
      path: "/",
      element: <PrivateRoutes />,
      children: [
        {
          path: "/",
          element: <DashboardLayout />,
          children: [
            { path: "/", element: <Dashboard /> },
            { path: "/manage/user", element: <ManageUsers /> },
            { path: "/manage/super-admin", element: <ManageSuperAdmin /> },
            { path: "/manage/admin", element: <ManageAdmin /> },
            { path: "/manage/agent", element: <ManageAgent /> },
            { path: "/manage/stockist", element: <ManageStokist /> },
            { path: "/manage/super-stockist", element: <ManageSuperStokist /> },
            { path: "/live-matches", element: <LiveMatches /> },
            { path: "/completed-matches", element: <CompletedMatches /> },
            { path: "/casino-report", element: <CasinoReports /> },
            { path: "/clients/my-clients", element: <MyClients /> },
            { path: "/clients/blocked-clients", element: <BlockedClients /> },
            {
              path: "/clients/commission-and-limits",
              element: <CommissionLimits />,
            },
            { path: "/language", element: <Languages /> },
            {
              path: "/ledger/collection-report",
              element: <CollectionReport />,
            },
            { path: "/ledger/company-ledger", element: <CompanyLedger /> },
            { path: "/ledger/profit-loss", element: <ProfitAndLoss /> },
            { path: "/ledger/my-statement", element: <MyStatements /> },
            { path: "/manage-password", element: <ManagePassword /> },
            { path: "/block-sports", element: <BlockedSports /> },
            { path: "/profile", element: <Profile /> },
            { path: "/members/masters", element: <Master /> },
            { path: "/block-sports/seriesList", element: <SeriesListing /> },
            {
              path: "/live-matches/reports/:match_id",
              element: <Reports />,
            },
            {
              path: "/completed-matches/reports/:match_id",
              element: <Reports />,
            },
            {
              path: "/live-matches/reports/:match_id/sessionbet-slip",
              element: <SessionReport />,
            },
            {
              path: "/completed-matches/reports/:match_id/sessionbet-slip",
              element: <SessionReport />,
            },
            {
              path: "/live-matches/reports/:match_id/live-report",
              element: <LiveReport />,
            },
            {
              path: "/completed-matches/reports/:match_id/live-report",
              element: <LiveReport />,
            },
          ],
        },
      ],
    },
    { path: "/login", element: <LoginPage /> },
  ]);
}
