import React, { useEffect, useState } from "react";
import axios from "../../helpers/axios";
import {
  Grid,
  Typography,
  CircularProgress,
  Box,
  Container,
  Paper,
} from "@mui/material";

export default function Dashboard() {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [localData, setLocalData] = useState();

  useEffect(() => {
    getUserData();
    setLocalData(localStorage.getItem("userProfile"));
  }, []);

  const getUserData = async () => {
    try {
      const response = await axios.get("/users/get-user", {
        params: {
          username: JSON.parse(localStorage.getItem("userProfile")).username,
        },
      });
      setUserData(response.data.user);
      console.log(response.data.user);
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <CircularProgress color="inherit" />;
  }

  const userProfile = JSON.parse(localStorage.getItem("userProfile"));

  return (
    <Container maxWidth="xl" sx={{ marginTop: "20px" }}>
      <Box sx={{ width: "100%" }}>
        <Typography variant="h5" color="black" gutterBottom>
          Welcome,
        </Typography>
        <Typography variant="h3" color="black" gutterBottom>
          {userProfile?.username || "User"}
        </Typography>
        <Typography variant="body1" color="#BCBCBF" gutterBottom>
          User Information
        </Typography>
        <Paper
          sx={{
            backgroundColor: "#fff",
            borderRadius: "15px",
            padding: "20px",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="h6" color="#64646B">
                My Username
              </Typography>
              <Typography variant="h5" color="black">
                {userProfile?.username}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="h6" color="#64646B">
                My Level
              </Typography>
              <Typography variant="h5" color="black">
                {userProfile?.user_type}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="h6" color="#64646B">
                My Fix Limit
              </Typography>
              <Typography variant="h5" color="black">
                {userProfile?.fix_limit}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="h6" color="#64646B">
                Maximum My Share
              </Typography>
              <Typography variant="h5" color="black">
                {userProfile?.my_match_share}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="h6" color="#64646B">
                Minimum Company Share
              </Typography>
              <Typography variant="h5" color="black">
                {userProfile?.other_match_share}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="h6" color="#64646B">
                Match Commission
              </Typography>
              <Typography variant="h5" color="black">
                {userProfile?.match_commission}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="h6" color="#64646B">
                Session Commission
              </Typography>
              <Typography variant="h5" color="black">
                {userProfile?.session_commission}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Container>
  );
}
