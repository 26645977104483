import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  TextField,
  Box,
  Container,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "../../helpers/axios";
import { useParams } from "react-router-dom";

export default function SessionReport() {
  const [sessionBetsData, setSessionBetsData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const { match_id } = useParams();

  function formatDate(dateString) {
    const date = new Date(dateString);

    const options = {
      timeZone: "Asia/Kolkata",
      hour12: true,
      hour: "numeric",
      minute: "numeric",
      day: "2-digit",
      month: "short",
      year: "numeric",
    };

    return date.toLocaleString("en-IN", options);
  }

  const columns = [
    {
      field: "date",
      headerName: "DATE",
      width: 180,
      valueGetter: (row) => formatDate(row.row.date),
    },
    { field: "fancy_id", headerName: "Fancy ID", width: 130 },
    { field: "user_name", headerName: "Username", width: 130 },
    { field: "session_title", headerName: "Session Title", width: 190 },
    { field: "rate", headerName: "RATE", width: 100 },
    { field: "runs", headerName: "RUNS", width: 100 },
    { field: "amount", headerName: "AMOUNT", width: 100 },
    { field: "mode", headerName: "MODE", width: 100 },
    { field: "no_position", headerName: "No Position", width: 130 },
    { field: "yes_position", headerName: "Yes Position", width: 130 },
    { field: "my_share", headerName: "My Share", width: 130 },
    { field: "no_share", headerName: "No", width: 100 },
    { field: "yes_share", headerName: "Yes", width: 100 },
    { field: "status", headerName: "Status", width: 120 },
    { field: "settlement", headerName: "Plus / Minus", width: 130 },
  ];

  const getSessionBet = async () => {
    try {
      const res = await axios.get("/analysis/getSessionBetSlips", {
        params: {
          user_id: localStorage.getItem("_id"),
          match_id: match_id,
        },
      });
      if (res.data.status) {
        setSessionBetsData(res.data.dataobj);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getSessionBet();
  }, []);

  const filteredData = sessionBetsData.filter((item) =>
    Object.values(item)
      .join(" ")
      .toLowerCase()
      .includes(filterText.toLowerCase())
  );

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Box>
        {/* InfoBox Alternative with Grid and Paper */}
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} sx={{ padding: 2 }}>
              <Typography variant="subtitle2" color="textSecondary">
                Total Bets
              </Typography>
              <Typography variant="h5">{sessionBetsData.length}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} sx={{ padding: 2 }}>
              <Typography variant="subtitle2" color="textSecondary">
                Settled Bets
              </Typography>
              <Typography variant="h5">
                {
                  sessionBetsData.filter((obj) => obj.status !== "Pending")
                    .length
                }
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} sx={{ padding: 2 }}>
              <Typography variant="subtitle2" color="textSecondary">
                Unsettled Bets
              </Typography>
              <Typography variant="h5">
                {
                  sessionBetsData.filter((obj) => obj.status === "Pending")
                    .length
                }
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} sx={{ padding: 2 }}>
              <Typography variant="subtitle2" color="textSecondary">
                Reverted Bets
              </Typography>
              <Typography variant="h5">0</Typography>
            </Paper>
          </Grid>
        </Grid>

        {/* Search Bar */}
        <Box mt={4} mb={2}>
          <TextField
            size="small"
            variant="outlined"
            placeholder="Search Here"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
        </Box>

        {/* Data Table */}
        <Box style={{ height: 500, width: "100%" }}>
          <DataGrid
            rows={filteredData}
            columns={columns}
            pageSize={10}
            getRowId={(row) => row._id}
            rowsPerPageOptions={[10]}
            pagination
          />
        </Box>
      </Box>
    </Container>
  );
}
