import {
  Box,
  Container,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {Group, Favorite, Person, TrendingUp} from '@mui/icons-material';
import MembersPage from "./Manage/ManageClients";
import {TabPanel} from '../helpers/utils';
import {useLocation, useNavigate} from 'react-router-dom';

// ----------------------------------------------------------------------

export default function Dashboard() {
  return (
    <>
      <Helmet>
        <title> Dashboard | Admin</title>
      </Helmet>
      <Typography>Home</Typography>
    </>
  );
}
