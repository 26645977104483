import React, { useState, useEffect } from "react";
import { Container, Box, Typography, Checkbox } from "@mui/material";
import axios from "../../helpers/axios";
import { DataGrid } from "@mui/x-data-grid";

const SeriesListingTable = () => {
  const [seriesData, setSeriesData] = useState([]);

  useEffect(() => {
    const fetchSeriesData = async () => {
      try {
        const result = await axios.get("/series/getSeries");
        setSeriesData(result.data.dataobj);
      } catch (err) {
        console.log(err);
      }
    };

    fetchSeriesData();
  }, []);

  const columns = [
    { field: "srNo", headerName: "Sr No.", flex: 1 },
    { field: "seriesName", headerName: "Series Name", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Checkbox
          checked={params.row.isSelected}
          onChange={(e) =>
            handleCheckboxChange(params.row.id, e.target.checked)
          }
        />
      ),
    },
  ];

  const handleCheckboxChange = (id, isChecked) => {
    // Update the seriesData state to reflect checkbox change
    const updatedData = seriesData.map((item) =>
      item.id === id ? { ...item, isSelected: isChecked } : item
    );
    setSeriesData(updatedData);
  };

  const mockSeriesData = [
    { id: 1, srNo: 1, seriesName: "Premier League", isSelected: false },
    { id: 2, srNo: 2, seriesName: "Champions League", isSelected: true },
    { id: 3, srNo: 3, seriesName: "La Liga", isSelected: false },
    { id: 4, srNo: 4, seriesName: "Bundesliga", isSelected: true },
    { id: 5, srNo: 5, seriesName: "Serie A", isSelected: false },
    { id: 6, srNo: 6, seriesName: "UEFA Europa League", isSelected: false },
    { id: 7, srNo: 7, seriesName: "World Cup", isSelected: true },
  ];

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 2 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Series Listing
        </Typography>
        <Box sx={{ height: 600, width: "100%" }}>
          <DataGrid
            rows={mockSeriesData}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10, 20, 50]}
            pagination
            autoHeight
            disableSelectionOnClick
          />
        </Box>
      </Box>
    </Container>
  );
};

export default SeriesListingTable;
