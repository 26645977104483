import React from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
// import Button from "@mui/material/Button";
// import DownloadPdf from "../../../Components/DownloadPdf/DownloadPdf";
// import CSVGenerator from "../../../Components/CSVGenrator/CSVGenerator";
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function CompanyLedgerTable() {
  const columns = [
    { field: "id", headerName: "DATE/TIME", width: 150 }, // Adjust width as needed
    { field: "pid", headerName: "ENTRY", width: 150 }, // Adjust width as needed
    { field: "title", headerName: "DEBIT", width: 150 }, // Adjust width as needed
    { field: "sports", headerName: "CREDIT", width: 150 }, // Adjust width as needed
    { field: "date", headerName: "BALANCE", width: 150 }, // Adjust width as needed
    { field: "profit_loss", headerName: "NOTE", width: 150 }, // Adjust width as needed
  ];

  const data = [
    {
      id: "2023-01-01 10:00 AM",
      pid: "ENTRY1",
      title: 50,
      sports: 0,
      date: 50,
      profit_loss: "Initial Balance",
    },
    {
      id: "2023-01-02 02:30 PM",
      pid: "ENTRY2",
      title: 0,
      sports: 30,
      date: 20,
      profit_loss: "Credit from X",
    },
    {
      id: "2023-01-03 08:45 AM",
      pid: "ENTRY3",
      title: 20,
      sports: 0,
      date: 40,
      profit_loss: "Debit for Y",
    },
    {
      id: "2023-01-04 04:15 PM",
      pid: "ENTRY4",
      title: 0,
      sports: 15,
      date: 25,
      profit_loss: "Credit from Z",
    },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <h2>Agent Ledger</h2>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        slots={{
          toolbar: CustomToolbar,
        }}
        disableSelectionOnClick
        autoHeight
      />
    </div>
  );
}
