import React, { useState } from "react";
import { TextField, Button, Collapse, IconButton, Grid } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const MasterUserLimit = ({ onClose }) => {
  const [openMarkets, setOpenMarkets] = useState({
    cricket: false,
    tennis: false,
    soccer: false,
  });

  const handleToggleMarket = (event) => {
    setOpenMarkets((prev) => ({
      ...prev,
      [event]: !prev[event],
    }));
  };

  return (
    <div style={{ maxHeight: "650px", overflowY: "auto", padding: "20px" }}>
      <h3 style={{ textAlign: "center" }}>Master User Limit</h3>
      {["Cricket", "Tennis", "Soccer"].map((event) => (
        <div key={event} style={{ marginBottom: "10px" }}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <IconButton
                onClick={() => handleToggleMarket(event.toLowerCase())}
              >
                {openMarkets[event.toLowerCase()] ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <AddCircleOutlineIcon />
                )}
              </IconButton>
            </Grid>
            <Grid item xs>
              <span>{event}</span>
            </Grid>
            <Grid item xs>
              <TextField
                label={`${event} Limit`}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </Grid>
          </Grid>

          <Collapse in={openMarkets[event.toLowerCase()]}>
            <div
              style={{
                paddingLeft: "20px",
                marginTop: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                fullWidth
                label="Book Market"
                variant="outlined"
                margin="normal"
              />
              <TextField
                fullWidth
                label="Fancy Market"
                variant="outlined"
                margin="normal"
              />
            </div>
          </Collapse>
        </div>
      ))}

      <TextField
        fullWidth
        label="Profit Limit"
        variant="outlined"
        margin="normal"
      />
      <TextField
        fullWidth
        label="Member Max Credit"
        variant="outlined"
        margin="normal"
        value="10000"
      />

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "20px",
        }}
      >
        <Button
          variant="outlined"
          style={{ marginRight: "10px" }}
          onClick={() => onClose()}
        >
          Cancel
        </Button>
        <Button variant="contained" color="primary">
          Save
        </Button>
      </div>
    </div>
  );
};

export default MasterUserLimit;
