import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, Modal, Stack, TextField, Container } from "@mui/material";
import axios from "../../helpers/axios";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";

export default function LiveMatches() {
  const params = useParams();
  const navigate = useNavigate();
  const [matches, setMatches] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [activeTabSport, setActiveTabSport] = useState(0);
  const [sports_id, setSports_id] = useState(4);

  useEffect(() => {
    const fetchData = async () => {
      if (activeTab === 0) {
        await getLiveMatches(sports_id);
      } else {
        await getUpcomingMatches(sports_id);
      }
    };

    fetchData();
  }, [activeTab, sports_id]);

  const getLiveMatches = async (sports_id) => {
    setMatches([]);
    try {
      let res = await axios.get("/matches/getLiveMatches/" + sports_id);
      if (res.data.status) {
        setMatches(res.data.dataobj);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getUpcomingMatches = async (sports_id) => {
    setMatches([]);
    try {
      let res = await axios.get("/matches/getUpcommingMatches/" + sports_id);
      if (res.data.status) {
        setMatches(res.data.dataobj);
      }
    } catch (err) {
      console.log(err);
    }
  };
  function formatDateAndTime(dateString) {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  const columns = [
    {
      field: "match_id",
      headerName: "ID",
      width: 100, // Adjust width as needed
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "match_name",
      headerName: "Name",
      width: 200, // Adjust width as needed
      disableColumnMenu: true,
      renderCell: (params) => (
        <Link
          to={`/live-matches/reports/${params.row.matchObj.id}`}
          style={{
            color: "#0080FF",
            display: "block",
            width: "100%",
            textOverflow: "ellipsis", // Ensure text does not overflow
            overflow: "hidden",
            whiteSpace: "nowrap", // Prevent wrapping
          }}
        >
          {params.row.match_name}
        </Link>
      ),
    },
    {
      field: "start_time",
      headerName: "Date",
      width: 150, // Adjust width as needed
      disableColumnMenu: true,
      renderCell: (params) => (
        <span style={{ width: "100%", textAlign: "center" }}>
          {formatDateAndTime(params.value)}
        </span>
      ),
    },
    {
      field: "profit_loss",
      headerName: "Profit / Loss",
      width: 150, // Adjust width as needed
      disableColumnMenu: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150, // Adjust width as needed
      disableColumnMenu: true,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            paddingTop: "12px",
            width: "100%",
          }}
        >
          <button
            style={{
              backgroundColor: "#9d2525",
              color: "white",
              border: "none",
              cursor: "pointer",
              padding: "5px 10px",
              textAlign: "center",
            }}
            onClick={() => {
              navigate(
                `/live-matches/reports/${params.row.matchObj.id}/live-report`
              );
            }}
          >
            Live Report
          </button>
        </div>
      ),
    },
  ];

  const returnCurrentTabTable = () => {
    if (activeTab === 0 && activeTabSport === 0)
      return (
        <div style={{ height: 800, width: "100%" }}>
          <div style={{ fontWeight: "Bold", paddingBottom: "10px" }}>
            Live Cricket Matches
          </div>

          <DataGrid
            rows={matches}
            columns={columns}
            getRowId={(row) => row._id}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 20, 30]}
            autoHeight
            disableSelectionOnClick
          />
        </div>
      );
    else if (activeTab === 0 && activeTabSport === 1)
      return (
        <div style={{ height: 800, width: "100%" }}>
          <div style={{ fontWeight: "Bold", paddingBottom: "10px" }}>
            Live Soccer Matches
          </div>

          <DataGrid
            rows={matches}
            columns={columns}
            getRowId={(row) => row._id}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 20, 30]}
            autoHeight
            disableSelectionOnClick
          />
        </div>
      );
    else if (activeTab === 0 && activeTabSport === 2)
      return (
        <div style={{ height: 800, width: "100%" }}>
          <div style={{ fontWeight: "Bold", paddingBottom: "10px" }}>
            Live Tennis Matches
          </div>

          <DataGrid
            rows={matches}
            columns={columns}
            getRowId={(row) => row._id}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 20, 30]}
            autoHeight
            disableSelectionOnClick
          />
        </div>
      );
    else if (activeTab === 1 && activeTabSport === 0)
      return (
        <div style={{ height: 800, width: "100%" }}>
          <div style={{ fontWeight: "Bold", paddingBottom: "10px" }}>
            Upcoming Cricket Matches
          </div>

          <DataGrid
            rows={matches}
            columns={columns}
            getRowId={(row) => row._id}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 20, 30]}
            autoHeight
            disableSelectionOnClick
          />
        </div>
      );
    else if (activeTab === 1 && activeTabSport === 1)
      return (
        <div style={{ height: 800, width: "100%" }}>
          <div style={{ fontWeight: "Bold", paddingBottom: "10px" }}>
            Upcoming Soccer Matches
          </div>

          <DataGrid
            rows={matches}
            columns={columns}
            getRowId={(row) => row._id}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 20, 30]}
            autoHeight
            disableSelectionOnClick
          />
        </div>
      );
    else if (activeTab === 1 && activeTabSport === 2)
      return (
        <div style={{ height: 800, width: "100%" }}>
          <div style={{ fontWeight: "Bold", paddingBottom: "10px" }}>
            Upcoming Tennis Matches
          </div>

          <DataGrid
            rows={matches}
            columns={columns}
            getRowId={(row) => row._id}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 20, 30]}
            autoHeight
            disableSelectionOnClick
          />
        </div>
      );
    else return <></>;
  };

  return (
    <div>
      <div style={{ overflow: "auto" }}>
        <div
          style={{
            padding: "16px 0",
            display: "grid",
            placeItems: "center",
            background: "",
            borderRadius: "8px",
            marginBottom: "8px",
          }}
        >
          {/* <Tabs
            value={activeTab}
            sx={{
              ".Mui-selected": {
                color: `#fff`,
                outline: "none",
              },
              "&:hover": {
                outline: "none",
              },
            }}
            TabIndicatorProps={{ style: { background: "#896cef" } }}
            aria-label="tabs example"
            onChange={(e, newVal) => {
              setActiveTab(newVal);
            }}
          >
            <Tab
              value={0}
              label={
                <span
                  style={{ color: activeTab === 0 ? "#896cef" : "#808080" }}
                >
                  In Play Matches
                </span>
              }
            />
            <Tab
              value={1}
              label={
                <span
                  style={{ color: activeTab === 1 ? "#896cef" : "#808080" }}
                >
                  Upcoming Matches
                </span>
              }
            />
          </Tabs> */}
        </div>
        <div
          style={{
            padding: "16px 0",
            display: "grid",
            placeItems: "center",
            background: "",
            borderRadius: "8px",
          }}
        >
          <Tabs
            value={activeTabSport}
            sx={{
              ".Mui-selected": {
                color: `#fff`,
                outline: "none",
              },
              "&:hover": {
                outline: "none",
              },
            }}
            TabIndicatorProps={{ style: { background: "#896cef" } }}
            aria-label="tabs example"
            onChange={(e, newVal) => {
              setActiveTabSport(newVal);
            }}
          >
            <Tab
              value={0}
              label={
                <span
                  style={{
                    color: activeTabSport === 0 ? "#896cef" : "#808080",
                  }}
                >
                  Cricket
                </span>
              }
            />
            <Tab
              value={1}
              label={
                <span
                  style={{
                    color: activeTabSport === 1 ? "#896cef" : "#808080",
                  }}
                >
                  Soccer
                </span>
              }
            />
            <Tab
              value={2}
              label={
                <span
                  style={{
                    color: activeTabSport === 2 ? "#896cef" : "#808080",
                  }}
                >
                  Tennis
                </span>
              }
            />
          </Tabs>
        </div>
        {returnCurrentTabTable()}
      </div>
    </div>
  );
}
