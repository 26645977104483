import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, Modal, Stack, TextField } from "@mui/material";
import NewManageUserForm from "../partials/NewManageUserForm";
import axiosInstance from "../../../helpers/axios";

const columns = [
  { field: "_id", headerName: "ID", width: 200, disableColumnMenu: true },
  {
    field: "username",
    headerName: "UserName",
    width: 130,
    disableColumnMenu: true,
  },
  {
    field: "first_name",
    headerName: "Name",
    width: 130,
    disableColumnMenu: true,
  },
  {
    field: "fix_limit",
    headerName: "Fix Limit",
    type: "number",
    width: 90,
    disableColumnMenu: true,
  },
  {
    field: "my_match_share",
    headerName: "My Share",
    description: "This column has a value getter and is not sortable.",
    width: 160,
    disableColumnMenu: true,
    valueGetter: (value, row) => `${row?.my_match_share || ""}`,
  },
  {
    field: "max_share",
    headerName: "Max Share",
    width: 160,
    disableColumnMenu: true,
    valueGetter: (value, row) => `${row?.max_share || ""}`,
  },
  {
    field: "exposure",
    headerName: "Exposure",
    width: 160,
    disableColumnMenu: true,
    valueGetter: (value, row) => `${row?.exposure || "0"}`,
  },
];

export default function ManageStokistTable() {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const userType = localStorage.getItem("user_type");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleFetchRows = async () => {
    try {
      const userDetails = JSON.parse(localStorage.getItem("userProfile"));
      const response = await axiosInstance.get(
        `users/get-users?user_type=stockist&user_id=${userDetails?._id}`
      );
      console.log(response.data);
      const data = response.data;
      setRows(data?.data);
    } catch (err) {
      console.error(err);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleAddUser = (newUser) => {
    setRows([...rows, newUser]);
  };

  useEffect(() => {
    handleFetchRows();
  }, []);
  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" mb={2}>
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
          size="small"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpen}
          sx={{ height: "32px" }}
        >
          Add Stokiest
        </Button>
      </Stack>

      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          getRowId={(row) => row._id}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
        />
        <Modal open={open} onClose={handleClose}>
          <div
            style={{
              padding: 20,
              background: "white",
              margin: "50px auto",
              maxWidth: 800,
              borderRadius: "15px",
            }}
          >
            <NewManageUserForm onClose={handleClose} userType={"Stokist"} />
          </div>
        </Modal>
      </div>
    </Box>
  );
}
