import React, { useState } from "react";
import { Switch, Grid, Typography, Box, Button } from "@mui/material";

const MasterUserSettings = ({ onClose }) => {
  const [eventStatus, setEventStatus] = useState({
    cricket: false,
    tennis: false,
    soccer: false,
  });

  const handleToggle = (event) => {
    setEventStatus({
      ...eventStatus,
      [event]: !eventStatus[event],
    });
  };

  return (
    <Box component="form">
      <Typography variant="h6" sx={{ mb: 3 }}>
        Master User Settings
      </Typography>

      {/* Event Table */}
      <Grid container spacing={2}>
        {/* Table Header */}
        <Grid item xs={12}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            Event
          </Typography>
        </Grid>

        {/* Cricket Row */}
        <Grid item xs={6}>
          <Typography>Cricket</Typography>
        </Grid>
        <Grid item xs={6}>
          <Switch
            checked={eventStatus.cricket}
            onChange={() => handleToggle("cricket")}
            color="primary"
          />
        </Grid>

        {/* Tennis Row */}
        <Grid item xs={6}>
          <Typography>Tennis</Typography>
        </Grid>
        <Grid item xs={6}>
          <Switch
            checked={eventStatus.tennis}
            onChange={() => handleToggle("tennis")}
            color="primary"
          />
        </Grid>

        {/* Soccer Row */}
        <Grid item xs={6}>
          <Typography>Soccer</Typography>
        </Grid>
        <Grid item xs={6}>
          <Switch
            checked={eventStatus.soccer}
            onChange={() => handleToggle("soccer")}
            color="primary"
          />
        </Grid>
      </Grid>

      {/* Buttons */}
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
        <Button variant="outlined" sx={{ mr: 2 }} onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          sx={{
            backgroundColor: "#735CFF",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#5c49cc",
            },
          }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default MasterUserSettings;
