import React, { useEffect, useState } from "react";
import { Box, Container, Stack, Tab, Tabs, Typography } from "@mui/material";
import LiveMatchesTable from "../../components/Matches/LiveMatchesTable";

const LiveMatches = () => {
  return (
    <Container maxWidth="xl" sx={{ marginTop: "10px" }}>
      <Box sx={{ width: "100%" }}>
        <LiveMatchesTable />
      </Box>
    </Container>
  );
};

export default LiveMatches;
