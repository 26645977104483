import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  Box,
  TextField,
  Button,
  IconButton,
  Typography,
  Checkbox,
} from "@mui/material";
import axios from "../../helpers/axios";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";

const BlockSport = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [colData, setColData] = useState([]);
  const [game, setData] = useState([]);
  const [btnClickFlag, setBtnClickFlag] = useState(false);

  const handleBtnClick = async (_id, status) => {
    try {
      const response = await axios.patch("game/updateStatus", { _id, status });
      alert(response.data.msg);
      setBtnClickFlag(!btnClickFlag);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get("/game/getGames", {});
        setData(result.data.dataobj);
        setColData(result.data.data.dataobj);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [btnClickFlag]);

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <TextField
        variant="outlined"
        placeholder="Search Here"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        size="small"
      />
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "gId", headerName: "BetfairId", flex: 1 },
    {
      field: "gName",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="text"
          onClick={() => navigate(`/block-sports/seriesList`)}
        >
          {params.row.gName}
        </Button>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Checkbox
          checked={params.row.block}
          onChange={() =>
            handleCheckboxChange(params.row.id, !params.row.block)
          }
          color={params.row.block ? "secondary" : "primary"}
        />
      ),
    },
  ];

  const handleCheckboxChange = (id, newBlockStatus) => {
    // Update the block status for the row with the given ID
    setData((prevData) =>
      prevData.map((row) =>
        row.id === id ? { ...row, block: newBlockStatus } : row
      )
    );
  };

  const mockData = [
    { id: "1", gId: "BF123", gName: "Soccer", block: false },
    { id: "3", gId: "BF125", gName: "Tennis", block: false },
    { id: "4", gId: "BF126", gName: "Cricket", block: true },
  ];

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 2 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Games
        </Typography>
        <Box sx={{ mb: 2 }}>{subHeaderComponentMemo}</Box>
        <Box sx={{ height: 600, width: "100%" }}>
          <DataGrid
            rows={mockData}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10, 20, 50]}
            pagination
            autoHeight
            disableSelectionOnClick
          />
        </Box>
      </Box>
    </Container>
  );
};

export default BlockSport;
