import React, { useMemo } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";

function MyStatementTable() {
  const columns = [
    { field: "id", headerName: "Date", width: 120 }, // Adjust width as needed
    { field: "pid", headerName: "Description", width: 200 }, // Adjust width as needed
    { field: "title", headerName: "Cr", width: 100 }, // Adjust width as needed
    { field: "sports", headerName: "Dbt", width: 100 }, // Adjust width as needed
    { field: "date", headerName: "Com+", width: 120 }, // Adjust width as needed
    { field: "profit_loss", headerName: "Com-", width: 120 }, // Adjust width as needed
    { field: "balance", headerName: "Balance", width: 150 }, // Adjust width as needed
  ];


  const data = [
    {
      id: "2023-01-01",
      pid: "Transaction1",
      title: 0,
      sports: 50,
      date: 0,
      profit_loss: "Debit",
      balance: 50,
    },
    {
      id: "2023-01-02",
      pid: "Transaction2",
      title: 30,
      sports: 0,
      date: 0,
      profit_loss: "Credit",
      balance: 20,
    },
    {
      id: "2023-01-03",
      pid: "Transaction3",
      title: 0,
      sports: 20,
      date: 0,
      profit_loss: "Debit",
      balance: 40,
    },
    {
      id: "2023-01-04",
      pid: "Transaction4",
      title: 15,
      sports: 0,
      date: 0,
      profit_loss: "Credit",
      balance: 25,
    },
    // Add more data rows as needed
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <div>
      <div
        style={{
          backgroundColor: "#c9c9c9",
          borderRadius: "10px",
          padding: "8px 16px",
          marginBottom: "16px",
        }}
      >
        <h4>Statement of User</h4>
        <div style={{ display: "flex" }}>
          <p>All Time Total :</p>
          <p style={{ fontWeight: 600 }}> 00</p>
        </div>
      </div>
      <div style={{ height: 600, width: "100%", marginTop: "16px" }}>
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10, 20, 50]}
          slots={{
            toolbar: CustomToolbar,
          }}
          disableSelectionOnClick
          autoHeight
        />
      </div>
    </div>
  );
}

export default MyStatementTable;
