import React, { useEffect, useState } from "react";
import { Box, Container, Stack, Tab, Tabs, Typography } from "@mui/material";
import ManageSuperStokistTable from "../../components/Manage/ManageSuperStokist/ManageSuperStokistTable";

const ManageSuperStokist = () => {
  return (
    <Container maxWidth="xl" sx={{ marginTop: "10px" }}>
      <Box sx={{ width: "100%" }}>
        <ManageSuperStokistTable />
      </Box>
    </Container>
  );
};

export default ManageSuperStokist;
