import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  TextField,
  Box,
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Search as SearchIcon,
  AccountCircle as AccountCircleIcon,
} from "@mui/icons-material";
import authService from "../../../services/auth.service";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  function logOut() {
    authService.logout();
    navigate("/login", { replace: true });
  }

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "#282c34", height: "60px" }}
    >
      <Toolbar>
        {/* Logo */}
        <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
          <Typography variant="h6" component="div">
            <img
              src="/assets/logo.png"
              alt="Logo"
              style={{ maxHeight: "40px" }}
            />
          </Typography>
        </Box>

        {/* Search Field (Visible in all views) */}
        <Box
          sx={{
            flexGrow: 2,
            display: "flex",
            justifyContent: isMobile ? "flex-start" : "center",
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Search..."
            size="small"
            InputProps={{
              startAdornment: <SearchIcon />,
            }}
            sx={{
              borderRadius: "20px",
              backgroundColor: "white",
              width: isMobile ? "100%" : "300px",
              ml: isMobile ? 1 : 0,
            }}
          />
        </Box>

        {/* Notifications with Marquee Effect */}
        {!isMobile && (
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Typography
                variant="body2"
                sx={{ animation: "marquee 10s linear infinite" }}
              >
                Your notification message here
              </Typography>
            </Box>
          </Box>
        )}

        {/* Profile Icon */}
        <Box sx={{ display: "flex", alignItems: "center", ml: 2 }}>
          <IconButton color="inherit" onClick={handleMenuClick}>
            <AccountCircleIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={logOut}>Logout</MenuItem>
          </Menu>
        </Box>

        {/* Mobile Menu Button */}
        {/* {isMobile && (
          <IconButton
            color="inherit"
            onClick={toggleDrawer(true)}
            sx={{ ml: 2 }}
          >
            <MenuIcon />
          </IconButton>
        )} */}

        {/* Mobile Drawer with Search Bar */}
        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
          <Box sx={{ width: 250 }}>
            <List>
              <ListItem>
                <TextField
                  variant="outlined"
                  placeholder="Search..."
                  size="small"
                  fullWidth
                  InputProps={{
                    startAdornment: <SearchIcon />,
                  }}
                  sx={{
                    borderRadius: "20px",
                    backgroundColor: "white",
                  }}
                />
              </ListItem>
              <ListItem button>
                <ListItemText primary="Profile" />
              </ListItem>
              <ListItem button>
                <ListItemText primary="Notifications" />
              </ListItem>
              <ListItem button>
                <ListItemText primary="Balance: $1234.56" />
              </ListItem>
              <ListItem button onClick={logOut}>
                <ListItemText primary="Logout" />
              </ListItem>
            </List>
          </Box>
        </Drawer>
      </Toolbar>

      <style>
        {`
          @keyframes marquee {
            0% { transform: translateX(100%); }
            100% { transform: translateX(-100%); }
          }
        `}
      </style>
    </AppBar>
  );
};

export default Header;
