import {useEffect, useState} from 'react';
import {
  Box,
  Container,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import {
  Group,
  Favorite,
  Person,
  TrendingUp,
  Settings,
  ManageAccounts,
  AttachMoney,
} from "@mui/icons-material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
//
import axiosInstance from "../../helpers/axios";
import Header from "./header";
import { useMediaQuery } from "@mui/material";

// ----------------------------------------------------------------------
const MenuOptionList = [
  {
    title: "My Market",
    path: "/live-matches",
    icon: <TrendingUp />,
  },
  {
    title: "Members",
    isExpandable: true,
    options: [
      {
        title: "Master",
        path: "/members/masters",
        icon: <Person />,
        allowedRoles: ["supreme_admin"],
      },
      {
        title: "User",
        path: "/manage/user",
        icon: <Person />,
        allowedRoles: [
          "supreme_admin",
          "super_admin",
          "admin",
          "super_stockist",
          "stockist",
          "agent",
        ],
      },
      {
        title: "My Clients",
        path: "/clients/my-clients",
        icon: <Person />,
      },
      {
        title: "Blocked Clients",
        path: "/clients/blocked-clients",
        icon: <Person />,
      },
    ],
    icon: <Group />,
  },
  // {
  //   title: "Manage",
  //   isExpandable: true,
  //   options: [
  //     {
  //       title: "Super Admin",
  //       path: "/manage/super-admin",
  //       icon: <Person />,
  //       allowedRoles: ["supreme_admin"],
  //     },
  //     {
  //       title: "Admin",
  //       path: "/manage/admin",
  //       icon: <Person />,
  //       allowedRoles: ["supreme_admin", "super_admin"],
  //     },
  //     {
  //       title: "Super Stockist",
  //       path: "/manage/super-stockist",
  //       icon: <Person />,
  //       allowedRoles: ["supreme_admin", "super_admin", "admin"],
  //     },
  //     {
  //       title: "Stockist",
  //       path: "/manage/stockist",
  //       icon: <Person />,
  //       allowedRoles: [
  //         "supreme_admin",
  //         "super_admin",
  //         "admin",
  //         "super_stockist",
  //       ],
  //     },
  //     {
  //       title: "Agent",
  //       path: "/manage/agent",
  //       icon: <Person />,
  //       allowedRoles: [
  //         "supreme_admin",
  //         "super_admin",
  //         "admin",
  //         "super_stockist",
  //         "stockist",
  //       ],
  //     },
  //     {
  //       title: "User",
  //       path: "/manage/user",
  //       icon: <Person />,
  //       allowedRoles: [
  //         "supreme_admin",
  //         "super_admin",
  //         "admin",
  //         "super_stockist",
  //         "stockist",
  //         "agent",
  //       ],
  //     },
  //   ],
  //   icon: <Group />,
  // },
  // {
  //   title: "Matches",
  //   isExpandable: true,
  //   options: [
  //     {
  //       title: "Live Matches",
  //       path: "/live-matches",
  //       icon: <Person />,
  //     },
  //     {
  //       title: "Completed Matches",
  //       path: "/completed-matches",
  //       icon: <Person />,
  //     },
  //   ],
  //   icon: <Person />,
  // },
  {
    title: "Casino Profit Loss",
    path: "/casino-report",
    icon: <AttachMoney />,
  },
  // {
  //   title: "Manage Clients",
  //   isExpandable: true,
  //   options: [
  //     {
  //       title: "My Clients",
  //       path: "/clients/my-clients",
  //       icon: <Person />,
  //     },
  //     {
  //       title: "Blocked Clients",
  //       path: "/clients/blocked-clients",
  //       icon: <Person />,
  //     },
  //     {
  //       title: "Commission & Limits",
  //       path: "/clients/commission-and-limits",
  //       icon: <Person />,
  //     },
  //   ],
  //   icon: <Person />,
  // },
  {
    title: "Manage ledgers",
    isExpandable: true,
    options: [
      {
        title: "Collection Report",
        path: "/ledger/collection-report",
        icon: <Person />,
      },
      {
        title: "Company Ledger",
        path: "/ledger/company-ledger",
        icon: <Person />,
      },
    ],
    icon: <ManageAccounts />,
  },
  {
    title: "Setting",
    isExpandable: true,
    options: [
      {
        title: "Profile",
        path: "/profile",
        icon: <Person />,
      },
      {
        title: "Block Sports",
        path: "/block-sports",
        icon: <Person />,
      },
      {
        title: "Manage Password",
        path: "/manage-password",
        icon: <Person />,
      },
      {
        title: "My Statement",
        path: "/ledger/my-statement",
        icon: <Person />,
      },
      {
        title: "Profit & Loss",
        path: "/ledger/profit-loss",
        icon: <Person />,
      },
      {
        title: "Language",
        path: "/language",
        icon: <Person />,
      },
    ],
    icon: <Settings />,
  },
];

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const [userProfile, setUserProfile] = useState();
  const [expandedMenu, setExpandedMenu] = useState(null);
  const [selectedSubMenu, setSelectedSubMenu] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const userRole = localStorage.getItem("user_type");

  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    const currentPath = location.pathname;
    const selectedItem = MenuOptionList.find(
      (item) =>
        item.path === currentPath ||
        (item.isExpandable &&
          item.options.some((subItem) => subItem.path === currentPath))
    );

    if (selectedItem) {
      setSelectedMenu(selectedItem.title);
      if (selectedItem.isExpandable) {
        setExpandedMenu(selectedItem.title);
        // Find the first accessible sub-menu item
        const accessibleSubMenu = selectedItem.options.find((subItem) =>
          subItem.allowedRoles?.includes(userRole)
        );
        console.log(accessibleSubMenu);
        if (accessibleSubMenu) {
          setSelectedSubMenu(accessibleSubMenu.path);
          navigate(accessibleSubMenu.path);
        } else {
          // Handle case where no sub-menu items are accessible
          setSelectedSubMenu(null);
        }
      } else {
        setSelectedSubMenu(currentPath);
        setExpandedMenu(null);
      }
    } else {
      setSelectedMenu("Dashboard");
      setSelectedSubMenu("/");
      setExpandedMenu(null);
    }
  }, [userRole]);

  // const handleMenuClick = item => {
  //   console.log(item);

  //   if (item.isExpandable) {
  //     setExpandedMenu(expandedMenu === item.title ? null : item.title);
  //     // Set the first accessible sub-menu item as default
  //     const accessibleSubMenu = item.options.find(subItem =>
  //       subItem.allowedRoles?.includes(userRole),
  //     );
  //     console.log(accessibleSubMenu);
  //     if (accessibleSubMenu) {
  //       navigate(accessibleSubMenu.path);
  //       setSelectedSubMenu(accessibleSubMenu.path);
  //     }
  //     //  else {
  //     //   navigate(item.options[0].path);
  //     //   setSelectedSubMenu(item.options[0].path);
  //     // }
  //   } else {
  //     if (item.allowedRoles && !item.allowedRoles.includes(userRole)) {
  //       // Do not navigate if the user does not have access
  //       return;
  //     }
  //     navigate(item.path);
  //     setSelectedMenu(item.title);
  //     setSelectedSubMenu(item.path);
  //     setExpandedMenu(null);
  //   }
  // };

  // const handleSubMenuClick = path => {
  //   const selectedItem = MenuOptionList.find(
  //     item =>
  //       item.options && item.options.some(subItem => subItem.path === path),
  //   );
  //   if (selectedItem) {
  //     const subItem = selectedItem.options.find(
  //       subItem => subItem.path === path,
  //     );
  //     if (
  //       subItem &&
  //       (!subItem.allowedRoles || subItem.allowedRoles.includes(userRole))
  //     ) {
  //       console.log(subItem);
  //       navigate(path);
  //       setSelectedSubMenu(path);
  //       setSelectedMenu(selectedItem.title);
  //     }
  //   }
  // };

  // Update the handleMenuClick to ensure the expanded state works correctly.
  const handleMenuClick = (item) => {
    if (item.isExpandable) {
      // Toggle expanded menu
      setExpandedMenu(expandedMenu === item.title ? null : item.title);

      // Automatically navigate to the first accessible sub-menu and highlight it
      const accessibleSubMenu = item.options.find(
        (subItem) =>
          !subItem.allowedRoles || subItem.allowedRoles.includes(userRole)
      );

      if (accessibleSubMenu) {
        setSelectedMenu(item.title);
        setSelectedSubMenu(accessibleSubMenu.path);
        navigate(accessibleSubMenu.path); // Navigate to the first sub-menu item
      }
    } else {
      if (item.allowedRoles && !item.allowedRoles.includes(userRole)) {
        return; // Do not navigate if user does not have access
      }
      navigate(item.path);
      setSelectedMenu(item.title);
      setSelectedSubMenu(null); // No sub-menu for non-expandable items
      setExpandedMenu(null); // Close any open expandable menu
    }
  };

  const handleSubMenuClick = (path) => {
    const selectedItem = MenuOptionList.find(
      (item) =>
        item.options && item.options.some((subItem) => subItem.path === path)
    );
    if (selectedItem) {
      const subItem = selectedItem.options.find(
        (subItem) => subItem.path === path
      );
      if (!subItem.allowedRoles || subItem.allowedRoles.includes(userRole)) {
        navigate(path);
        setSelectedSubMenu(path);
        setSelectedMenu(selectedItem.title);
      }
    }
  };

  const renderMenuItems = (items) => {
    return (
      <Box sx={{ width: "100%" }}>
        {/* Main Menu */}
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "row" : "row",
            justifyContent: isMobile ? "space-evenly" : "flex-start",
            position: isMobile ? "fixed" : "relative",
            bottom: isMobile ? 0 : "unset",
            width: isMobile ? "100%" : "100%",
            padding: isMobile ? "8px 0" : "unset",
            backgroundColor: isMobile ? "#d6d6d6" : "",
            zIndex: 10, // Ensure menu is above the table
            pointerEvents: "none", // Disable clicks on background
          }}
        >
          {items
            .filter(
              (item) =>
                !item.allowedRoles || item.allowedRoles.includes(userRole)
            )
            .map((item, index) => (
              <Box
                key={index}
                sx={{
                  position: "relative",
                  margin: isMobile ? 0 : 1,
                  flex: isMobile ? "1 1 auto" : 1,
                  textAlign: "center",
                  pointerEvents: "auto", // Ensure IconButton is clickable
                }}
              >
                <IconButton
                  onClick={() => handleMenuClick(item)}
                  sx={{
                    borderRadius: isMobile ? "50%" : "2%",
                    padding: isMobile ? "8px" : 1,
                    justifyContent: "center",
                    color: selectedMenu === item.title ? "#1976d2" : "inherit",
                    pointerEvents: "auto", // Ensure this button can be clicked
                  }}
                >
                  {item.icon}
                </IconButton>
                {!isMobile && (
                  <Typography
                    variant="body2"
                    sx={{
                      color:
                        selectedMenu === item.title ? "#1976d2" : "inherit",
                      fontWeight:
                        selectedMenu === item.title ? "bold" : "normal",
                    }}
                  >
                    {item.title}
                  </Typography>
                )}
              </Box>
            ))}
        </Box>

        {/* Submenu for Desktop */}
        {expandedMenu && !isMobile && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#f0f0f0",
              padding: 1,
              width: "100%",
              boxSizing: "border-box",
              pointerEvents: "auto", // Ensure submenu is clickable
            }}
          >
            {items
              .filter(
                (item) => item.title === expandedMenu && item.isExpandable
              )
              .map((item) =>
                item.options
                  .filter(
                    (subItem) =>
                      !subItem.allowedRoles ||
                      subItem.allowedRoles.includes(userRole)
                  )
                  .map((subItem, subIndex) => (
                    <Box
                      key={subIndex}
                      sx={{
                        color:
                          selectedSubMenu === subItem.path
                            ? "#1976d2"
                            : "inherit",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        cursor: "pointer",
                        padding: "4px 8px",
                        textAlign: "center",
                        flex: 1,
                      }}
                      onClick={() => handleSubMenuClick(subItem.path)}
                    >
                      {subItem.icon}
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "0.75rem",
                          color:
                            selectedSubMenu === subItem.path
                              ? "#1976d2"
                              : "inherit",
                          fontWeight:
                            selectedSubMenu === subItem.path
                              ? "bold"
                              : "normal",
                        }}
                      >
                        {subItem.title}
                      </Typography>
                    </Box>
                  ))
              )}
          </Box>
        )}

        {/* Dropdown Submenu for Mobile */}
        {expandedMenu && isMobile && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#f0f0f0",
              padding: 1,
              width: "100%",
              boxSizing: "border-box",
              pointerEvents: "auto", // Ensure dropdown is clickable
            }}
          >
            <Select
              value={selectedSubMenu || ""}
              onChange={(e) => handleSubMenuClick(e.target.value)}
              displayEmpty
              sx={{
                width: "100%",
                backgroundColor: "#fff",
                borderRadius: "4px",
              }}
              renderValue={(selected) => {
                if (!selected) {
                  return <em>Select an option</em>;
                }
                return items
                  .find(
                    (item) => item.title === expandedMenu && item.isExpandable
                  )
                  ?.options.find((subItem) => subItem.path === selected)?.title;
              }}
            >
              {items
                .filter(
                  (item) => item.title === expandedMenu && item.isExpandable
                )
                .map((item) =>
                  item.options
                    .filter(
                      (subItem) =>
                        !subItem.allowedRoles ||
                        subItem.allowedRoles.includes(userRole)
                    )
                    .map((subItem, subIndex) => (
                      <MenuItem key={subIndex} value={subItem.path}>
                        {subItem.icon}
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "0.75rem",
                            marginLeft: "8px",
                            color:
                              selectedSubMenu === subItem.path
                                ? "#1976d2"
                                : "inherit", // Highlight selected text in blue
                            fontWeight:
                              selectedSubMenu === subItem.path
                                ? "bold"
                                : "normal",
                          }}
                        >
                          {subItem.title}
                        </Typography>
                      </MenuItem>
                    ))
                )}
            </Select>
          </Box>
        )}
      </Box>
    );
  };

  useEffect(() => {
    setUserProfile(JSON.parse(localStorage.getItem("userProfile")));
  }, []);

  return (
    <>
      <Header onOpenNav={() => setOpen(true)} userProfile={userProfile} />
      <Box
        sx={{
          backgroundColor: "#f5f5f5",
          padding: isMobile ? "0px" : "16px",
          overflowX: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            justifyContent: "space-evenly",
          }}
        >
          {renderMenuItems(MenuOptionList)}
        </Box>
      </Box>
      <Outlet />
    </>
  );
}
