import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "../../helpers/axios";
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export default function ManagePassword() {
  const [popUpShow, setPopUpShow] = useState(false);
  const [msg, setMsg] = useState("");
  const [title, setTitle] = useState("");

  const formik = useFormik({
    initialValues: {
      oldPass: "",
      newPass: "",
      confirmPass: "",
    },
    validationSchema: Yup.object({
      oldPass: Yup.string().required("Old Password is required"),
      newPass: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .required("New Password is required"),
      confirmPass: Yup.string()
        .oneOf([Yup.ref("newPass"), null], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: async (values) => {
      try {
        const response = await axios.put(
          `/auth/change-password/${localStorage.getItem("_id")}`,
          {
            password: values.newPass,
          }
        );
        console.log("Password changed successfully.");
        setMsg("Password changed successfully.");
        setTitle("Success");
        setPopUpShow(true);
      } catch (error) {
        console.error("Error changing password.");
        setMsg("Error in changing password.");
        setTitle("Error");
        setPopUpShow(true);
      }
    },
  });

  const okFun = () => {
    setPopUpShow(false);
  };

  return (
    <Container maxWidth="sm">
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          mt: 4,
          p: 3,
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Typography variant="h5" component="h5">
          Change Password
        </Typography>
        <TextField
          label="Old Password"
          type="password"
          variant="outlined"
          fullWidth
          {...formik.getFieldProps("oldPass")}
          error={formik.touched.oldPass && Boolean(formik.errors.oldPass)}
          helperText={formik.touched.oldPass && formik.errors.oldPass}
        />
        <TextField
          label="New Password"
          type="password"
          variant="outlined"
          fullWidth
          {...formik.getFieldProps("newPass")}
          error={formik.touched.newPass && Boolean(formik.errors.newPass)}
          helperText={formik.touched.newPass && formik.errors.newPass}
        />
        <TextField
          label="Confirm Password"
          type="password"
          variant="outlined"
          fullWidth
          {...formik.getFieldProps("confirmPass")}
          error={
            formik.touched.confirmPass && Boolean(formik.errors.confirmPass)
          }
          helperText={formik.touched.confirmPass && formik.errors.confirmPass}
        />
        <Button type="submit" variant="contained" color="primary">
          Done
        </Button>
      </Box>
      <Dialog open={popUpShow} onClose={okFun}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{msg}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={okFun} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
