import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Typography, Snackbar, Alert, Tabs, Tab } from "@mui/material";

const DwcForm = ({ onClose, userType }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [activeTab, setActiveTab] = useState(0); // To track active tab
  const [formValues, setFormValues] = useState({
    my_balance: 900,
    my_balance_after: 900,
    old_credit_limit: 5000,
    user_balance: 100,
    user_balance_after: 100,
  });

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: Yup.object({
      my_balance: Yup.number().required("Required"),
      my_balance_after: Yup.number().required("Required"),
      old_credit_limit: Yup.number().required("Required"),
      new_credit_limit: Yup.number().required("Required"),
      user_balance: Yup.number().required("Required"),
      user_balance_after: Yup.number().required("Required"),
      transaction_amount: Yup.number().required("Required"),
      remark: Yup.string(),
    }),
    onSubmit: (values) => {
      // The rest of the form submission logic
    },
  });

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    // Update form values based on transaction_amount and activeTab
    if (activeTab === 0) {
      // Deposit
      formik.setFieldValue(
        "user_balance_after",
        formValues.user_balance_after +
          parseFloat(formValues.transaction_amount)
      );
      formik.setFieldValue(
        "my_balance_after",
        formValues.my_balance_after - parseFloat(formValues.transaction_amount)
      );
    } else if (activeTab === 1) {
      // Withdraw
      formik.setFieldValue(
        "my_balance_after",
        formValues.my_balance_after + parseFloat(formValues.transaction_amount)
      );
      formik.setFieldValue(
        "user_balance_after",
        formValues.user_balance_after -
          parseFloat(formValues.transaction_amount)
      );
    }
  }, [formValues.transaction_amount, activeTab]);

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        maxHeight: "80vh", // Limit the height of the form
        overflowY: "auto", // Enable vertical scrolling
        p: 2,
      }}
    >
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        centered
        sx={{ mb: 2 }}
      >
        <Tab label="Deposit" />
        <Tab label="Withdraw" />
      </Tabs>

      <Typography variant="h6" sx={{ mb: 3 }}>
        {activeTab === 0 ? "Deposit of" : "Withdraw of"} {userType}
      </Typography>

      <Grid container spacing={2}>
        {/* My Balance */}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="my_balance"
            name="my_balance"
            type="number"
            label="My Balance"
            variant="outlined"
            value={formik.values.my_balance}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled
          />
        </Grid>

        {/* My Balance After */}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="my_balance_after"
            name="my_balance_after"
            type="number"
            label="My Balance After"
            variant="outlined"
            value={formik.values.my_balance_after}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled
          />
        </Grid>

        {/* Old Credit Limit */}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="old_credit_limit"
            name="old_credit_limit"
            type="number"
            label="Old Credit Limit"
            variant="outlined"
            value={formik.values.old_credit_limit}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled
          />
        </Grid>

        {/* New Credit Limit */}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="new_credit_limit"
            name="new_credit_limit"
            type="number"
            label="New Credit Limit"
            variant="outlined"
            value={formik.values.new_credit_limit}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled
          />
        </Grid>

        {/* User Balance */}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="user_balance"
            name="user_balance"
            type="number"
            label="User Balance"
            variant="outlined"
            value={formik.values.user_balance}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled
          />
        </Grid>

        {/* User Balance After */}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="user_balance_after"
            name="user_balance_after"
            type="number"
            label="User Balance After"
            variant="outlined"
            value={formik.values.user_balance_after}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled
          />
        </Grid>

        {/* Deposit or Withdraw Amount */}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="transaction_amount"
            name="transaction_amount"
            type="number"
            label={activeTab === 0 ? "Deposit Amount" : "Withdraw Amount"}
            variant="outlined"
            value={formik.values.transaction_amount}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                transaction_amount: e.target.value,
              })
            }
            onBlur={formik.handleBlur}
          />
        </Grid>

        {/* Remark */}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="remark"
            name="remark"
            type="text"
            label="Remark"
            variant="outlined"
            value={formik.values.remark}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Grid>
      </Grid>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "flex-end",
          mt: 3,
        }}
      >
        <Button
          variant="outlined"
          sx={{ mb: 2, sm: { mr: 2 } }}
          onClick={() => onClose()}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          sx={{
            mb: 2,
            ml: 2,
            backgroundColor: "#735CFF",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#5c49cc",
            },
          }}
        >
          Submit
        </Button>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default DwcForm;
