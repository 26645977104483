import React, {useState} from 'react';
import {
  Box,
  Card,
  CardContent,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
  Button,
} from '@mui/material';
import {
  Visibility,
  VisibilityOff,
  AccountCircle,
  Lock,
  Refresh,
} from '@mui/icons-material';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import axiosInstance from '../helpers/axios';
import sha256 from 'sha256';
import {useNavigate} from 'react-router-dom';

const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [captcha, setCaptcha] = useState(generateCaptcha());
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      captchaInput: '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required('Username is required'),
      password: Yup.string().required('Password is required'),
      captchaInput: Yup.string()
        .required('Please enter the captcha')
        .test('captcha-match', 'Captcha does not match', function (value) {
          return value === captcha;
        }),
    }),
    onSubmit: async values => {
      try {
        const response = await axiosInstance.post('/auth/login-panel', {
          username: values.username,
          password: sha256(values.password),
        });
        console.log(response);
        localStorage.setItem('token', response.data.accessToken);
        localStorage.setItem('userProfile', JSON.stringify(response.data));
        localStorage.setItem('user_type', response.data.user_type);
        localStorage.setItem('isAuthenticated', true);
        // store permissions once added in the api
        // localStorage.setItem('permissions', data?.user?.permissions);

        //based on permission navigate the usere

        // if (data?.user?.permissions?.includes('super_admin')) {
        navigate('/', {replace: true});
        //   return;
        // }
      } catch (error) {
        console.log(error);
      }
      // Reset captcha after successful submission
      setCaptcha(generateCaptcha());
    },
  });

  function generateCaptcha() {
    return Math.floor(1000 + Math.random() * 9000).toString();
  }

  const handleRefreshCaptcha = () => {
    setCaptcha(generateCaptcha());
    formik.setFieldValue('captchaInput', ''); // Clear the captcha input field
  };

  return (
    <Card
      sx={{
        backgroundColor: 'whitesmoke',
        borderRadius: '15px',
        boxShadow: '0 0 25px #544d81',
        maxWidth: '300px',
        width: '100%',
      }}
    >
      <CardContent>
        <form onSubmit={formik.handleSubmit}>
          <Box mb={2}>
            <Typography variant="caption" display="block" gutterBottom>
              Username
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              id="username"
              name="username"
              placeholder="Username"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
                sx: {
                  borderRadius: '10px',
                  height: '40px',
                },
              }}
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
            />
          </Box>

          <Box mb={2}>
            <Typography variant="caption" display="block" gutterBottom>
              Password
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              id="password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                ),
                sx: {
                  borderRadius: '10px',
                  height: '40px',
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Box>

          <Box mb={2}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mb={1}
              p={1}
              bgcolor="#f0f0f0"
              borderRadius="4px"
              fontWeight="bold"
              fontSize="18px"
              letterSpacing="2px"
            >
              {captcha}
            </Box>
            <TextField
              fullWidth
              variant="outlined"
              id="captchaInput"
              name="captchaInput"
              placeholder="Enter Captcha"
              value={formik.values.captchaInput}
              onChange={formik.handleChange}
              error={
                formik.touched.captchaInput &&
                Boolean(formik.errors.captchaInput)
              }
              helperText={
                formik.touched.captchaInput && formik.errors.captchaInput
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="refresh captcha"
                      onClick={handleRefreshCaptcha}
                    >
                      <Refresh fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
                sx: {
                  borderRadius: '10px',
                  height: '40px',
                },
              }}
            />
          </Box>

          <Button
            sx={{
              backgroundColor: '#735cff',
            }}
            variant="contained"
            fullWidth
            type="submit"
          >
            Login
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};

export default LoginForm;
