import React, {useEffect} from 'react';
import {Box, Stack} from '@mui/material';
import LoginForm from '../components/LoginForm';
import {useLocation, useNavigate} from 'react-router-dom';

const LoginPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = localStorage.getItem('isAuthenticated');
  // const permissions = localStorage.getItem('permissions');
  useEffect(() => {
    // if (permissions && permissions.split(',').includes('super_admin')) {
    //   navigate('/dashboard', {replace: true});
    //   return;
    // }
    if (isAuthenticated) {
      // const {from} = location.state || {from: {pathname: '/dashboard'}};
      // navigate(from, {replace: true});
      navigate('/dashboard', {replace: true});
    }
  }, [isAuthenticated, location, navigate]);
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#282c34',
        flexDirection: 'column',
      }}
    >
      <img
        src="/assets/logo.png"
        alt="Logo"
        style={{maxWidth: '150px', marginBottom: '20px'}}
      />
      <LoginForm />
    </Box>
  );
};

export default LoginPage;
