import React, { useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Box, Button } from "@mui/material";

export default function Reports() {
  const navigate = useNavigate();
  const { match_id } = useParams();
  const location = useLocation();
  const [backPath, setbackPath] = useState(location.pathname.split("/")[1]);

  return (
    <Box display="flex" justifyContent="center" mt={2}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          navigate("/" + backPath + "/reports/" + match_id + "/live-report");
        }}
        sx={{ marginRight: 2 }}
      >
        Live Report
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          navigate(
            "/" + backPath + "/reports/" + match_id + "/sessionbet-slip"
          );
        }}
      >
        Session Report
      </Button>
    </Box>
  );
}
