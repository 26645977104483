import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

export default function CasinoReport() {
  const [value, onChange] = useState([new Date(), new Date()]);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
    },
    {
      field: "sports",
      headerName: "Sports",
      flex: 1,
    },
    {
      field: "profit_loss",
      headerName: "Profit/Loss",
      flex: 1,
    },
  ];

  const data = [
    {
      id: 1, // Ensure each row has a unique ID
      title: "India vs Pakistan",
      sports: "Cricket",
      profit_loss: "20",
      date: new Date().toLocaleString(),
    },
    {
      id: 2, // Ensure each row has a unique ID
      title: "India vs Pakistan",
      sports: "Cricket",
      profit_loss: "20",
      date: new Date().toLocaleString(),
    },
  ];

  return (
    <div>
      {/* <DateRangePicker
        onChange={onChange}
        value={value}
        clearIcon={<MdOutlineClear color="white" />}
        calendarIcon={<SlCalender color="white" />}
        format={"dd-MM-y"}
      /> */}
      {/* <Table title="Casino Loss and Profit" data={data} columns={columns} /> */}
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={data}
          columns={columns}
          getRowId={(row) => row.id} // Use the correct property for ID
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
          //   checkboxSelection
        />
      </div>
    </div>
  );
}
