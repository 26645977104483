import React, { useEffect, useState } from "react";
import { Box, Container, Stack, Tab, Tabs, Typography } from "@mui/material";
import MyStatementsTable from "../../components/Settings/MyStatementsTable";

const MyStatements = () => {
  return (
    <Container maxWidth="xl" sx={{ marginTop: "10px" }}>
      <Box sx={{ width: "100%" }}>
        <MyStatementsTable />
      </Box>
    </Container>
  );
};

export default MyStatements;
