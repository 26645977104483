import React, { useEffect, useState } from "react";
import { Box, Container, Stack, Tab, Tabs, Typography } from "@mui/material";
import CommissionLimitsTable from "../../components/ManageClients/CommissionLimitsTable";

const CommissionLimits = () => {
  return (
    <Container maxWidth="xl" sx={{ marginTop: "10px" }}>
      <Box sx={{ width: "100%" }}>
        <CommissionLimitsTable />
      </Box>
    </Container>
  );
};

export default CommissionLimits;
